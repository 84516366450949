export interface RecentQuery {
  key: number;
  value: string;
  quickSearch?: boolean;
  name?: string;
  address?: string;
}

export interface RouteFeature {
  name: string;
  id: number;
  address: string;
}

export enum QueryParamBinary {
  ENABLED = "enable",
  DISABLED = "disable",
}

export enum QueryParam {
  STEP_FREE = "step-free",
  FEATURE_ID = "feature",
  QUERY = "query",
  MILESTONE_INDEX = "milestone-index",
  QUICK_SEARCH = "quick-search",
  NAME_SEARCH = "name-search",
  LANG = "lang",
}

export enum MapQueryParam {
  UI_CONTROLS = "ui-controls",
  UI_GESTURES = "ui-gestures",
  UI_SEARCH = "ui-search",
  UI_FEATURE = "ui-feature",
  GEOLOCATION = "geolocation",
  FLOOR = "floor",
  MARKER = "marker",
  CALLBACK = "callback",
  DEBUG = "debug",
  PLUGIN = "plugin",
  CONSOLE_LOCATION = "console-location",
}

// to combine enums we need to declare a type and a const with the same name https://stackoverflow.com/questions/52857698/extending-enum-in-typescript/71255520#71255520
export type QueryParams = QueryParam | MapQueryParam;
// eslint-disable-next-line
export const QueryParams = { ...QueryParam, ...MapQueryParam };

export enum Path {
  FEATURE = "poi",
  SEARCH = "search",
  ROUTE = "route/:fromName/:fromId/:toName/:toId",
  NAVIGATE = "navigate/:fromName/:fromId/:toName/:toId",
  JOURNEY = "journey/:fromName/:fromId/:toName/:toId",
  JOURNEY_OVERVIEW = "journey-overview/:fromName/:fromId/:toName/:toId",
}

export enum RoutingPath {
  ROUTE = "route",
  NAVIGATE = "navigate",
  JOURNEY = "journey",
  JOURNEY_OVERVIEW = "journey-overview",
}

export type routingPaths =
  | RoutingPath.ROUTE
  | RoutingPath.NAVIGATE
  | RoutingPath.JOURNEY
  | RoutingPath.JOURNEY_OVERVIEW;
