import classNames from "classnames";
import { useRef, useState } from "react";
import { Outlet } from "react-router-dom";

import { useRecentSearches } from "../../../../hooks/useRecentSearches";
import useResponsive from "../../../../hooks/useResponsive";
import { useSearchBarProps } from "../../../../hooks/useSearchBarProps";
import { useSearchSuggestions } from "../../../../hooks/useSearchSuggestions";
import { useAppSelector } from "../../../../redux/hooks";

import SearchTagsContainer from "../../../../containers/SearchTagsContainer/SearchTagsContainer";

import ListItemResult, {
  COLOURS,
} from "../../../../components/ListItemResult/ListItemResult";
import Panel from "../../../../components/Panel/Panel";
import SearchBar from "../../../../components/SearchBar/SearchBar";

import styles from "./DesktopHomeView.module.scss";
import { useTranslation } from "react-i18next";

export interface HomeProps {
  onClickSearchSuggestion: (query: string, nameSearch: boolean) => void;
}

export default function DesktopHomeView({
  onClickSearchSuggestion,
}: HomeProps) {
  const { Default } = useResponsive();
  const { t } = useTranslation();

  const { queryParamsConfig, mapOptionsConfig } = useAppSelector(
    (state) => state.application,
  );

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [recentSearchActiveHover, setRecentSearchActiveHover] = useState(true);
  const [searchActivePosition, setSearchActivePosition] = useState(-1);
  const [showSearchHistory, setShowSearchHistory] = useState(true);

  const { handleClickRecentSearch, recentSearches, parsedRecentQueries } =
    useRecentSearches({
      onClickCallback: () => setRecentSearchActiveHover(false),
      checkActiveCallback: (index) => searchActivePosition === index,
      className: styles.searchResult,
      activeClassName: styles.activeSelect,
      icon: "HistoryIcon",
    });

  const { searchSuggestion, setSearchSuggestion, searchSuggestionsList } =
    useSearchSuggestions({
      limit: 5,
      className: styles.searchResult,
      highlightedClassName: styles.activeSelect,
      highlightedIndex: searchActivePosition,
      icon: "LocationOutlineIcon",
      handleOnClick: (value) => {
        setSearchSuggestion("");
        onClickSearchSuggestion(value, true);
      },
    });

  const isActivePositionMovableDown = (): boolean => {
    if (searchSuggestionsList.length > 0) {
      return searchActivePosition < searchSuggestionsList.length - 1;
    } else {
      return searchActivePosition < parsedRecentQueries.length - 1;
    }
  };

  const searchBarProps = useSearchBarProps({
    ref: searchInputRef,
    className: styles.searchbar,
    onClose: () => setShowSearchHistory(true),
    onChange: (value: string) => {
      setShowSearchHistory(value.length === 0);
      setSearchSuggestion(value.length > 1 ? value : "");
    },
    onKeyDown: (key: string): boolean => {
      if (key === "ArrowDown") {
        if (isActivePositionMovableDown()) {
          setSearchActivePosition(searchActivePosition + 1);
          return true;
        }
      } else if (key === "ArrowUp") {
        if (searchActivePosition > -1) {
          setSearchActivePosition(searchActivePosition - 1);
          return true;
        }
      } else if (key === "Enter") {
        if (searchSuggestionsList.length > 0) {
          if (searchActivePosition > -1) {
            searchSuggestionsList[searchActivePosition].props.onClick();
            searchInputRef.current?.blur();
            return true;
          }
        } else {
          const item = parsedRecentQueries[searchActivePosition];
          setSearchActivePosition(-1);
          if (item) {
            handleClickRecentSearch(item.value, item.quickSearch!);
            searchInputRef.current?.blur();
            return true;
          }
        }
      } else {
        setSearchActivePosition(-1);
      }

      return false;
    },
  });

  return (
    <>
      {Default && (
        <>
          <div className={styles.panelContainer}>
            {queryParamsConfig["ui-search"] !== "hide" &&
              queryParamsConfig["ui-controls"] !== "hide" &&
              mapOptionsConfig.search && (
                <div className={styles.searchResultsContainer}>
                  <SearchBar
                    {...searchBarProps}
                    dataQA="desktop-search"
                    onFocus={() => {
                      setRecentSearchActiveHover(true);
                      setSearchSuggestion("");
                      setSearchActivePosition(-1);
                    }}
                  />

                  {parsedRecentQueries.length > 0 &&
                    searchSuggestionsList.length === 0 &&
                    showSearchHistory && (
                      <Panel
                        dataQA="recent-search"
                        className={classNames(styles.searchItems, {
                          [styles.activeHover]: recentSearchActiveHover,
                        })}
                      >
                        {recentSearches}
                      </Panel>
                    )}
                  {searchSuggestion.length > 1 && (
                    <Panel
                      dataQA="search-suggestions"
                      className={classNames(styles.searchItems, {
                        [styles.activeHover]: recentSearchActiveHover,
                      })}
                    >
                      {searchSuggestionsList}
                      <ListItemResult
                        key={"submit-typed-search"}
                        className={styles.searchResult}
                        title={t("home.search_suggestion_text", {
                          query: searchSuggestion,
                        })}
                        dataQA="submit-typed-search"
                        icon="SearchIcon"
                        color={COLOURS.BLUE}
                        onClick={() =>
                          onClickSearchSuggestion(searchSuggestion, false)
                        }
                      />
                    </Panel>
                  )}
                </div>
              )}
            <Outlet />
          </div>
          {queryParamsConfig["ui-search"] !== "hide" &&
            queryParamsConfig["ui-controls"] !== "hide" &&
            mapOptionsConfig.search && (
              <SearchTagsContainer onClose={searchBarProps.onClose} />
            )}
        </>
      )}
    </>
  );
}
