import { useState } from "react";

import { setLocationStatus } from "../redux/slices/applicationSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useGetMapConfigQuery } from "../redux/services/vectormapsAPI";

import { LocationStatus } from "../components/LocationButton/LocationButton";
import { useTranslation } from "react-i18next";

enum LocationPermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
  PROMPT = "prompt",
}

export default function useLocationPermission({
  setLocationMarker,
}: {
  setLocationMarker: ({
    latitude,
    longitude,
    bearing,
    altitude,
    accuracy,
  }: {
    latitude: number;
    longitude: number;
    bearing: number | null;
    altitude: number | null;
    accuracy: number;
  }) => void;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { queryParamsConfig } = useAppSelector((state) => state.application);
  const [promptInitialised, setPromptInitialised] = useState(false);

  let watchId: number;

  const { data: mapData } = useGetMapConfigQuery();

  const clearUserLocationWatch = () => {
    if (navigator.geolocation && watchId) {
      navigator.geolocation.clearWatch(watchId);
    }
  };

  const requestUserLocation = () => {
    dispatch(setLocationStatus(LocationStatus.SEARCHING));

    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        handleUserLocationSuccess,
        handleUserLocationFailed,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
      );
    }
  };

  const handlePermissions = (state: PermissionState) => {
    switch (state) {
      case LocationPermissionStatus.PROMPT:
        requestUserLocation();
        setPromptInitialised(true);
        break;
      case LocationPermissionStatus.GRANTED:
        requestUserLocation();
        break;
      case LocationPermissionStatus.DENIED: // this state seems to only happen on chrome
        alertDeclinedLocationPermission();
        dispatch(setLocationStatus(LocationStatus.DISABLED));
        break;
      default:
        break;
    }
  };

  const fallbackGeolocationPermissionCheck = () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      () => {
        handlePermissions("granted");
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          handlePermissions("denied");
        } else {
          console.error("Geolocation error:", error);
        }
      },
    );
  };

  const handleLocationRequest = async () => {
    if (queryParamsConfig.consoleLocation !== "enable") {
      try {
        if ("permissions" in navigator && "query" in navigator.permissions) {
          const { state } = await navigator.permissions.query({
            name: "geolocation",
          });
          handlePermissions(state);
        } else {
          fallbackGeolocationPermissionCheck();
        }
      } catch (error) {
        fallbackGeolocationPermissionCheck();
      }
    }
  };

  const handleUserLocationSuccess: PositionCallback = (position) => {
    const {
      coords: { latitude, longitude, heading, altitude, accuracy },
    } = position;
    const bearing = heading;

    if (
      watchId &&
      mapData &&
      (longitude < mapData.extents.bottom_left.longitude ||
        longitude > mapData.extents.top_right.longitude ||
        latitude < mapData.extents.bottom_left.latitude ||
        latitude > mapData.extents.top_right.latitude)
    ) {
      clearUserLocationWatch();
      alert(t("home.user_location_out_of_bounds"));
      dispatch(setLocationStatus(LocationStatus.INACTIVE));
      return;
    }

    dispatch(setLocationStatus(LocationStatus.FOUND));

    setLocationMarker &&
      setLocationMarker({ latitude, longitude, bearing, altitude, accuracy });
  };

  const handleUserLocationFailed: PositionErrorCallback = (error) => {
    if (promptInitialised && error.code === error.PERMISSION_DENIED)
      alertDeclinedLocationPermission();

    dispatch(setLocationStatus(LocationStatus.DISABLED));
  };

  const alertDeclinedLocationPermission = () =>
    alert(t("home.user_location_declined_alert"));

  return {
    handleLocationRequest,
  };
}
