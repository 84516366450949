import { Spinner, Icon } from "@livingmap/core-ui-v2";

import styles from "./LoadingMapView.module.scss";
import { useTranslation } from "react-i18next";

const LoadingMapView = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <Spinner dataQA="loading-spinner" type="BeatLoader" />
      </div>
      <div className={styles.logoContainer}>
        <span className={styles.logoText}>
          {t("loading_map_view.logo_text")}
        </span>
        <Icon dataQA="loader-logo" type="LmLogoIcon" className={styles.logo} />
      </div>
    </div>
  );
};

export default LoadingMapView;
