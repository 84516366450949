import { FC, useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import ListItemResult, {
  COLOURS,
} from "../../../../components/ListItemResult/ListItemResult";
import { LocationStatus } from "../../../../components/LocationButton/LocationButton";
import { RECENT_POIS_KEY } from "../../../../constants";
import useRecentQueries from "../../../../hooks/useRecentQueries";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { buildRoutingPath } from "../../../../utils/buildRoutingPath";
import { push } from "../../../../utils/navigate";
import { truncateString } from "../../../../utils/truncateString";
import { QueryParams, RoutingPath } from "../../../../utils/types";
import RoutingListItemGroup from "../RoutingListItemGroup/RoutingListItemGroup";
import { MapContext } from "../../../../contexts/MapContext";

import styles from "./RoutingQuickActions.module.scss";
import useResponsive from "../../../../hooks/useResponsive";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface Props {
  onLocationRequest: () => void;
  onChooseFromMapButtonClick?: () => void;
  recentItemsLimit?: number;
  showQuickActions?: boolean;
}

export const RoutingQuickActions: FC<Props> = ({
  onLocationRequest,
  onChooseFromMapButtonClick,
  recentItemsLimit = 10,
  showQuickActions = true,
}) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { Mobile, Default } = useResponsive();
  const { t } = useTranslation();

  const { fromName, fromId, toName, toId } = useParams();

  const { locationStatus, queryParamsConfig } = useAppSelector(
    (state) => state.application,
  );

  const { positionControl } = useContext(MapContext);

  const [useUserLocation, setUseUserLocation] = useState(false);

  const { parsedRecentQueries } = useRecentQueries(RECENT_POIS_KEY, 10);

  const locationInUse = fromId?.includes(",") || toId?.includes(",");

  const handleLocationButtonClick = () => {
    setUseUserLocation(true);

    if (locationStatus !== LocationStatus.FOUND) {
      onLocationRequest();
    }
  };

  const handleRecentPoiClick = (id: string, name?: string) => {
    dispatch(
      push({
        pathOrLocation: state?.selectDestination
          ? buildRoutingPath(RoutingPath.NAVIGATE, {
              fromName,
              fromId,
              toName: name,
              toId: id,
            })
          : buildRoutingPath(RoutingPath.NAVIGATE, {
              fromName: name,
              fromId: id,
              toName,
              toId,
            }),
        discardParams: [QueryParams.QUERY],
      }),
    );
  };

  // Watch for changes in user location or coords and redirect to route view
  useEffect(() => {
    const position = positionControl?.getCurrentPosition();
    if (
      useUserLocation &&
      locationStatus === LocationStatus.FOUND &&
      position
    ) {
      const coords = `${position.longitude},${position.latitude}`;
      dispatch(
        push({
          pathOrLocation: state?.selectDestination
            ? buildRoutingPath(RoutingPath.NAVIGATE, {
                fromName,
                fromId,
                toId: coords,
              })
            : buildRoutingPath(RoutingPath.NAVIGATE, {
                fromId: coords,
                toName,
                toId,
              }),
          discardParams: [QueryParams.QUERY],
        }),
      );
    }
  }, [
    dispatch,
    toId,
    toName,
    fromId,
    fromName,
    positionControl,
    state?.selectDestination,
    useUserLocation,
    locationStatus,
  ]);

  return (
    <div className={styles.container}>
      {showQuickActions && (
        <>
          <div
            className={classNames(styles.quickActions, {
              [styles.empty]:
                queryParamsConfig["geolocation"] === "disable" && Default,
            })}
          >
            {(!locationInUse || locationStatus !== LocationStatus.FOUND) &&
              queryParamsConfig["geolocation"] !== "disable" && (
                <ListItemResult
                  onClick={handleLocationButtonClick}
                  dataQA="choose-current-location-list-item"
                  title={t("route_page.your_location_button")}
                  color={
                    locationStatus === LocationStatus.SEARCHING
                      ? COLOURS.GREY
                      : COLOURS.BLUE
                  } // TODO: currently no loading state
                  icon="NearMeIcon"
                  className={styles.quickActionButton}
                />
              )}
            {Mobile && (
              <ListItemResult
                onClick={onChooseFromMapButtonClick}
                dataQA="choose-from-map-list-item"
                title={t("route_page.choose_from_map_button")}
                color={COLOURS.BLUE}
                icon="LocationIcon"
              />
            )}
          </div>
          {/* Only render the divider if geolocation is not disabled or if it's a mobile device */}
          {(queryParamsConfig["geolocation"] !== "disable" || Mobile) && (
            <div className={styles.divider}></div>
          )}
        </>
      )}
      <div
        className={classNames(styles.recentPois, {
          [styles.noMarginTop]: !showQuickActions,
        })}
      >
        <RoutingListItemGroup
          icon="HistoryIcon"
          items={parsedRecentQueries
            .sort((a, b) => b.key - a.key)
            .map(({ name, address, value }) => {
              const truncatedName = name ? truncateString(name) : "";
              const truncatedAddress = address ? truncateString(address) : "";

              return {
                name: truncatedName,
                address: truncatedAddress,
                onClick: () => handleRecentPoiClick(value, name),
              };
            })
            .slice(0, recentItemsLimit)}
        />
      </div>
    </div>
  );
};

export default RoutingQuickActions;
