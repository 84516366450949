import { IconType } from "@livingmap/core-ui-v2";
import ListItemResult from "../../../../components/ListItemResult/ListItemResult";

import styles from "./RoutingListItemGroup.module.scss";

interface Props {
  items: { name: string; address: string; onClick: () => void }[];
  icon: IconType;
}

export default function RoutingListItemGroup({ items, icon }: Props) {
  return (
    <div className={styles.container}>
      {items.map(({ name, address, onClick }, index) => (
        <ListItemResult
          key={`${name}-${index}`}
          dataQA="routing-list-item"
          title={name}
          subtitle={address}
          icon={icon}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
