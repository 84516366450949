import classNames from "classnames";

import { useAppDispatch } from "../redux/hooks";
import { usePostAnalyticsEvent } from "./usePostAnalyticsEvent";
import { useQuickSearchTags } from "./useQuickSearchTags";
import useRecentQueries from "./useRecentQueries";

import { AnalyticsEvent } from "../utils/analyticsTypes";
import { push } from "../utils/navigate";
import { truncateString } from "../utils/truncateString";
import { QueryParamBinary, QueryParams } from "../utils/types";

import { RECENT_SEARCHES_KEY } from "../constants";
import { IconType } from "@livingmap/core-ui-v2";

import ListItemResult from "../components/ListItemResult/ListItemResult";

/**
 * Hook to build recent searches JSX elements
 */
export const useRecentSearches = ({
  className,
  activeClassName,
  onClickCallback,
  checkActiveCallback,
  icon,
}: {
  onClickCallback?: () => void;
  checkActiveCallback?: (index: number) => boolean;
  className?: string;
  activeClassName?: string;
  icon?: IconType;
}) => {
  const dispatch = useAppDispatch();

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const { parsedRecentQueries, addRecentQuery } =
    useRecentQueries(RECENT_SEARCHES_KEY);

  const { getQuickSearchTagFromId } = useQuickSearchTags();

  const displayIcon = icon ? icon : "SearchIcon";

  const handleClickRecentSearch = (query: string, quickSearch: boolean) => {
    onClickCallback && onClickCallback();

    addRecentQuery(query, { quickSearch });

    logAnalyticsEvent({
      event_type: AnalyticsEvent.SEARCH_QUERY_SEND,
      event_data: {
        query: quickSearch ? getQuickSearchTagFromId(query) : query,
        quick_search: quickSearch,
        quick_search_id: quickSearch ? Number(query) : null,
      },
    });

    dispatch(
      push({
        pathOrLocation: "/search",
        newQueryParams: {
          [QueryParams.QUERY]: query,
          ...(quickSearch && {
            [QueryParams.QUICK_SEARCH]: QueryParamBinary.ENABLED,
          }),
        },
        discardParams: [
          QueryParams.FEATURE_ID,
          ...(!quickSearch ? [QueryParams.QUICK_SEARCH] : []),
        ],
      }),
    );
  };

  const recentSearches = parsedRecentQueries
    .sort((a, b) => b.key - a.key)
    .map(({ value, key, quickSearch = false }, index) => {
      const truncatedValue = quickSearch
        ? truncateString(getQuickSearchTagFromId(value), 20)
        : truncateString(value, 20);

      return (
        <ListItemResult
          key={key}
          className={classNames(className, {
            ...(activeClassName && {
              [activeClassName]:
                checkActiveCallback && checkActiveCallback(index),
            }),
          })}
          title={truncatedValue}
          dataQA="recent-search-item"
          icon={displayIcon}
          onClick={() => handleClickRecentSearch(value, quickSearch)}
        />
      );
    });

  return {
    recentSearches,
    // handleClickRecentSearch and parsedRecentQueries needs to be returned because they are currently needed for the suggested search results
    handleClickRecentSearch,
    parsedRecentQueries,
  };
};
