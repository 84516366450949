import { FC, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useResponsive from "../../hooks/useResponsive";
import styles from "./FeatureInformationPanelView.module.scss";
import {
  BottomSheetRef,
  Button,
  Chip,
  HorizontalScroll,
  Icon,
  Sheet,
} from "@livingmap/core-ui-v2";
import classNames from "classnames";
import ShareDialog from "../../components/ShareDialog/ShareDialog";
import createLMFeature from "../../utils/createLMFeature";
import useFeatureData from "./hooks/useFeatureData";
import { getFeatureProps } from "./utils/getFeatureProps";
import { checkIsMobile } from "../../utils";
import FeatureInformationPanel from "../../components/FeatureInformationPanel/FeatureInformationPanel";
import CollapsablePanel from "../../components/CollapsablePanel/CollapsablePanel";
import { ENVIRONMENT, RECENT_POIS_KEY } from "../../constants";
import { push, replace } from "../../utils/navigate";
import { LocationStatus } from "../../components/LocationButton/LocationButton";
import { storeLocation } from "../../redux/slices/applicationSlice";
import useRecentQueries from "../../hooks/useRecentQueries";
import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { Path, QueryParams, RoutingPath } from "../../utils/types";
import { parseLanguageObject } from "../../utils/parseLanguageObject";
import { MapContext } from "../../contexts/MapContext";
import { useTransportService } from "../../hooks/useTransportService";
import { generateTextColourFromBackgroundColour } from "../../utils/textColour";
import OpenTimeSpan from "../../components/OpenTimeSpan/OpenTimeSpan";
import { useTranslation } from "react-i18next";

const FeatureInformationPanelView: FC = () => {
  const {
    interactionControl,
    floorControl,
    clusteredPinControl,
    positionControl,
  } = useContext(MapContext);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    savedLocation,
    locationStatus,
    language,
    project,
    queryParamsConfig,
    mapOptionsConfig,
  } = useAppSelector((state) => state.application);

  const { Default, Mobile } = useResponsive();

  const [searchParams] = useSearchParams();
  const id = searchParams.get(QueryParams.FEATURE_ID);

  const { featureData, transportFeeds } = useFeatureData(id);
  const {
    selectedTransportService,
    setSelectedTransportService,
    handleTransportDepartureClick,
    transportInformation,
    transportIdentifiers,
  } = useTransportService(transportFeeds);

  const { addRecentQuery } = useRecentQueries(RECENT_POIS_KEY, 10);

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const sheetRef = useRef<BottomSheetRef | null>(null);

  // i18next translation hook
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedTransportService(null);
    if (!featureData || !interactionControl || !id) return;

    const lmFeature = createLMFeature(featureData, language);

    interactionControl?.deselectFeatures();
    interactionControl?.selectFeature(lmFeature, {
      useFeatureSelectHandler: false,
    });
    clusteredPinControl?.reloadClusteredPins();

    // go to the floor of the feature
    const name = parseLanguageObject(featureData.location.floor.name, language);
    name &&
      floorControl?.setActiveFloor({
        ...featureData.location.floor,
        name,
      });
  }, [
    featureData,
    interactionControl,
    language,
    id,
    floorControl,
    setSelectedTransportService,
    clusteredPinControl,
  ]);

  if (!featureData) {
    return null;
  }

  const handlePanelClose = () => {
    if (interactionControl) {
      interactionControl.deselectFeatures();
    }

    dispatch(
      replace({
        pathOrLocation:
          savedLocation && savedLocation.pathname !== `/${Path.FEATURE}`
            ? savedLocation
            : "/", // Navigate to a default location "/" if storedLocation is not available
        discardParams: [QueryParams.FEATURE_ID],
        ...(savedLocation ? { state: savedLocation.state } : {}),
      }),
    );
  };

  const handleDirectionsClick = () => {
    addRecentQuery(featureData.uid, {
      name: featureProps.name,
      address: featureData.street_address || "",
    });

    dispatch(storeLocation(location));

    const position = positionControl?.getCurrentPosition();
    dispatch(
      push({
        pathOrLocation:
          locationStatus === LocationStatus.FOUND && position
            ? buildRoutingPath(RoutingPath.NAVIGATE, {
                fromId: `${position.longitude},${position.latitude}`,
                toName: `${featureProps.name}`,
                toId: `${featureData.uid}`,
              })
            : buildRoutingPath(RoutingPath.ROUTE, {
                toName: `${featureProps.name}`,
                toId: `${featureData.uid}`,
              }),
        discardParams: [QueryParams.FEATURE_ID, QueryParams.QUERY],
      }),
    );
  };

  const featureProps = getFeatureProps(
    featureData,
    transportInformation,
    language,
    handlePanelClose,
    handleDirectionsClick,
    handleTransportDepartureClick,
  );

  // generate the share url based on the environment
  const shareUrl = () => {
    if (ENVIRONMENT === "prod") {
      return `livingmap.link/f/${project}/${featureProps.uid}`;
    }

    return `shortlink-api.${ENVIRONMENT}.livingmap.com/v1/f/${project}/${featureProps.uid}`;
  };

  const handleShare = async () => {
    if (!checkIsMobile()) {
      setIsShareDialogOpen(true);
    } else {
      try {
        await navigator.share({
          url: `https://${shareUrl()}`,
        });
      } catch (e) {
        throw new Error("Unable to trigger navigator share");
      }
    }
  };

  const header = (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>{featureProps.name}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.summary}>{featureProps.summary}</p>
          {(featureProps.operatingHours ||
            featureProps.isTemporarilyClosed) && (
            <div>
              <OpenTimeSpan
                dataQA="feature-open-time-span"
                operatingHours={featureProps.operatingHours}
                isTemporarilyClosed={featureProps.isTemporarilyClosed}
              />
            </div>
          )}
        </div>
        <div className={styles.closeButtonContainer}>
          <Icon
            dataQA="close-feature-information-panel-icon"
            type="CloseIcon"
            onClick={featureProps.onClose}
          />
        </div>
      </div>
      <div className={styles.interactionButtons}>
        {mapOptionsConfig.directions && (
          <Button
            dataQA="feature-directions-button"
            onClick={featureProps.onDirectionsClick}
            icon={
              locationStatus !== LocationStatus.FOUND
                ? "NearMeTopIcon"
                : "WalkingPersonIcon"
            }
            rounded
            className={styles.directionsButton}
          >
            {t("poi.navigate_button")}
          </Button>
        )}
        {queryParamsConfig["callback"] ? (
          <div className={styles.callbackButton}>
            <Button
              dataQA="callback-button"
              onClick={() =>
                window.parent.postMessage(
                  "SELECTFEATURE:" + featureData.id,
                  "*",
                )
              }
              color="white"
              icon="CallbackIcon"
              rounded
              className={styles.button}
            >
              {queryParamsConfig["callback"]}
            </Button>
          </div>
        ) : (
          <Button
            dataQA="feature-share-button"
            onClick={handleShare}
            color="black"
            icon="ShareIcon"
            outlined
            rounded
          >
            {t("poi.share_button")}
          </Button>
        )}
        <ShareDialog
          dataQA="feature-share-dialog"
          isOpen={isShareDialogOpen}
          url={shareUrl()}
          description={t("share_dialog.body")}
          onClose={() => setIsShareDialogOpen(false)}
        />
      </div>
      {transportIdentifiers &&
        transportIdentifiers.length > 0 &&
        transportInformation?.transportMode !== "rail" && (
          <HorizontalScroll
            dataQA="transport-services-filter"
            className={styles.horizontalScrollContainer}
          >
            {transportIdentifiers.map((service) => {
              const serviceIsSelected =
                service.code === selectedTransportService;

              const serviceColour = service.colour.length
                ? service.colour
                : null;

              const defaultServiceColour = serviceColour ?? "#EEEEEE";
              const selectedServiceColour = serviceColour ?? "#212121";
              const unselectedServiceColour = "#EEEEEE";

              const backgroundColour = selectedTransportService
                ? serviceIsSelected
                  ? selectedServiceColour
                  : unselectedServiceColour
                : defaultServiceColour;

              const textColour =
                generateTextColourFromBackgroundColour(backgroundColour);

              return (
                <Chip
                  key={service.code}
                  dataQA={`transport-service-${service}`}
                  onClick={() => {
                    Mobile &&
                      sheetRef.current?.snapTo(
                        ({ snapPoints }) => snapPoints[1], // Snap back to closed state on cancel search
                      );
                    serviceIsSelected
                      ? setSelectedTransportService(null)
                      : setSelectedTransportService(service.code);
                  }}
                  noShadow
                  active={serviceIsSelected}
                  style={{
                    background: backgroundColour,
                    color: textColour,
                    borderRadius: "6px",
                  }}
                >
                  {service.code}
                </Chip>
              );
            })}
          </HorizontalScroll>
        )}
    </>
  );

  const hasContent =
    featureProps.imageUrl ||
    featureProps.description ||
    featureProps.metaData.length ||
    featureProps.transportInformation ||
    featureProps.operatingHours;

  return (
    <>
      {queryParamsConfig["ui-controls"] !== "hide" &&
        queryParamsConfig["ui-feature"] !== "hide" && (
          <>
            {Default && (
              <CollapsablePanel
                dataQA="feature-information-panel"
                isOpen
                className={classNames(
                  styles.container,
                  styles.desktopContainer,
                )}
                header={header}
                contentClassName={styles.content}
              >
                {hasContent && <FeatureInformationPanel {...featureProps} />}
              </CollapsablePanel>
            )}
            {Mobile && (
              <Sheet
                scrollLocking={false}
                ref={sheetRef}
                dataQA="feature-information-sheet"
                open
                className={classNames(styles.container, styles.mobileContainer)}
                snapPoints={({ maxHeight, headerHeight }) => [
                  headerHeight,
                  maxHeight - 20,
                ]}
                header={header}
                defaultSnap={({ headerHeight }) => headerHeight}
              >
                {hasContent ? (
                  <FeatureInformationPanel {...featureProps} />
                ) : (
                  <div style={{ padding: 10 }}></div>
                )}
              </Sheet>
            )}
          </>
        )}
    </>
  );
};

export default FeatureInformationPanelView;
