import { Icon, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { FC } from "react";

import styles from "./ListItemResult.module.scss";

export enum COLOURS {
  BLUE = "blue",
  GREY = "grey",
}

interface Props {
  dataQA: string;
  className?: string;
  title: string;
  subtitle?: string;
  loading?: boolean;
  icon?: IconType;
  color?: COLOURS;
  removable?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}

const ListItemResult: FC<Props> = ({
  dataQA,
  color = COLOURS.GREY,
  icon = "SearchIcon",
  loading = false,
  onClick,
  onClose,
  title,
  className,
  subtitle,
  removable = false,
}) => {
  return loading ? (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingIcon}></div>
      <div className={styles.loadingContent}>
        <div className={styles.loadingLine}></div>
        <div className={styles.loadingLine}></div>
      </div>
    </div>
  ) : (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[color])}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <Icon
          type={icon}
          dataQA="icon"
          className={styles.icon}
          width={18}
          height={18}
        />
      </div>
      <div className={styles.content}>
        <span
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
      {removable && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onClose && onClose();
          }}
        >
          <Icon
            type="CloseIcon"
            dataQA="remove"
            className={styles.removeIcon}
            width={14}
            height={14}
          />
        </span>
      )}
    </div>
  );
};

export default ListItemResult;
