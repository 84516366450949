import { Point, Polygon, Feature } from "geojson";
import { GeoJSONSource, Map } from "mapbox-gl";
import { createGeoJSONFeature } from "@livingmap/core-mapping/dist/utils";

let gMapbox: Map | undefined;
let gLastPoint: Point | undefined;
let gCounter: number = 0;
let gPoints: Point[] = [];
let gProperties: LocationProperties | undefined;

export interface LocationProperties {
  heading: number;
  accuracy: number;
  floor_id?: number;
}

export default function animateBetweenTwoPoints(
  mapbox: Map,
  newPoint: Point,
  properties: LocationProperties,
) {
  gMapbox = mapbox;
  gPoints = [];
  gProperties = properties;

  if (gLastPoint) {
    const [nx, ny] = newPoint.coordinates;
    const [lx, ly] = gLastPoint.coordinates;

    const diffX = nx - lx;
    const diffY = ny - ly;
    const pointNum = 10;

    const intervalX = diffX / (pointNum + 1);
    const intervalY = diffY / (pointNum + 1);

    for (let i = 0; i < pointNum; i++) {
      const x = Number((lx + intervalX * i).toFixed(7));
      const y = Number((ly + intervalY * i).toFixed(7));
      gPoints.push({
        type: "Point",
        coordinates: [x, y],
      });
    }
  }
  gPoints.push(newPoint);
  gLastPoint = newPoint;
  gCounter = 0;
  animateMarker(0);
}

function animateMarker(timestamp: number) {
  if (gMapbox && gCounter < gPoints.length) {
    const userLocationFeature = createGeoJSONFeature(
      gProperties!,
      gPoints[gCounter],
    );
    const iconLayer = gMapbox.getSource(
      "user-location-layer-source",
    ) as GeoJSONSource;
    iconLayer.setData(userLocationFeature);
    const polygonLayer = gMapbox.getSource(
      "user-location-accuracy-source",
    ) as GeoJSONSource;
    polygonLayer.setData(
      createGeoJSONCircle(gPoints[gCounter], gProperties!.accuracy || 0),
    );
    gCounter += 1;
    requestAnimationFrame(animateMarker);
  }
}

function createGeoJSONCircle(point: Point, radius: number): Feature<Polygon> {
  const points = 64;

  const km = radius;

  const coords = {
    latitude: point.coordinates[1],
    longitude: point.coordinates[0],
  };

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [ret],
    },
    properties: {},
  };
}
