import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
} from "@livingmap/core-mapping";
import {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} from "@livingmap/core-mapping/dist/utils";
import { FeatureCollection, Geometry, Point, Feature } from "geojson";
import { SymbolLayout } from "mapbox-gl";

import RoutingPlugin from "./routing-control";
import animateBetweenTwoPoints from "../../../utils/locationAnimator";
import { Queue } from "../../../utils/queue";
import { breakpoints } from "../../../hooks/useResponsive";

const USER_LOCATION_LAYER_ID = "user-location-layer";
const USER_LOCATION_ACCURACY_ID = "user-location-accuracy-layer";
const LOCATION_TRACKING_LAYER_ID = "location-tracking-layer";
const LOCATION_TRACKING_LINE_LAYER_ID = "location-tracking-line-layer";
const USER_LOCATION_ACCURACY_SOURCE_ID = "user-location-accuracy-source";
const USER_LOCATION_SOURCE_ID = `${USER_LOCATION_LAYER_ID}-source`;
const LOCATION_TRACKING_SOURCE_ID = "location-tracking-source";
const LOCATION_TRACKING_LINE_SOURCE_ID = "location-tracking-line-source";
export const EMPTY_DATA_SOURCE: FeatureCollection<Geometry> =
  createGeoJSONFeatureCollection([]);

interface Marker {
  bearing: number | null;
  altitude: number | null;
  accuracy: number;
  latitude: number;
  longitude: number;
  floor: string;
  floorID: number;
  ts: Date;
}

interface UserLocationColour {
  r: number;
  g: number;
  b: number;
}

export default class PositionPlugin extends LivingMapPlugin {
  protected layerDelegate: LayerDelegate;
  private routingControl: RoutingPlugin;
  private userLocationColour: UserLocationColour;
  private borderColour: string;
  private markerQueue: Queue<Marker>;
  private trackingQueue: Queue<Marker>;
  private headingDisplayed: boolean;
  private positionOnRoute: boolean;
  private positioningStabalised: boolean;
  private averagedAccuracy: number;
  private debugMode: boolean;
  private isMobile: boolean;
  private lastPosition: Point | null = null;

  constructor(
    id: string,
    LMMap: LivingMap,
    positionOnRoute: boolean,
    routingControl: RoutingPlugin,
    debugMode?: boolean,
  ) {
    super(id, LMMap);
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.routingControl = routingControl;
    this.userLocationColour = { r: 60, g: 120, b: 255 };
    this.borderColour = "#fff";
    this.markerQueue = new Queue<Marker>(3);
    this.trackingQueue = new Queue<Marker>(999);
    this.headingDisplayed = false;
    this.positionOnRoute = positionOnRoute;
    this.positioningStabalised = false;
    this.averagedAccuracy = 0;
    this.debugMode = debugMode || false;
    this.isMobile = window.innerWidth <= breakpoints.Mobile;
  }

  public activate(): void {
    this.createUserLocationLayer();
    if (this.debugMode) this.createLocationTrackingLayer();
  }

  public deactivate = () => {
    this.removeLocationLayers();
  };

  public setMarker(location: Marker): {
    latitude: number;
    longitude: number;
  } | null {
    const oldPosition = this.getCurrentPosition();
    this.updateLocationSource(location);
    const newPosition = this.getCurrentPosition();

    if (!newPosition) return null;
    if (
      !oldPosition ||
      (oldPosition.longitude !== newPosition.longitude &&
        oldPosition.latitude !== newPosition.latitude)
    )
      return newPosition;
    return null;
  }

  private generateAccuracyCircle(currentPosition: Point): number {
    const currentLat = currentPosition.coordinates[1];
    const currentLong = currentPosition.coordinates[0];

    const positions = this.markerQueue.fetch();
    // finds the largest distance between the current and previous points to create a accuracy circle
    const max = positions.reduce((accumulator, actualPosition) => {
      const acLon = actualPosition.longitude;
      const acLat = actualPosition.latitude;
      // Compares two coordinates and calculate distance using pythagoras theorem
      const distance = Math.sqrt(
        (acLon - currentLong) ** 2 + (acLat - currentLat) ** 2,
      );
      return Math.max(accumulator, distance);
    }, 0);
    // increase to give output in kilometers
    return Math.max(max * 75, this.averagedAccuracy / 1.5);
  }

  private updateLocationSource(location: Marker): void {
    this.trackingQueue.insert(location);
    if (this.debugMode) this.updateTrackingLayer();

    // Do not update location if the location is the same as the last one
    if (
      location.latitude === this.markerQueue.last?.latitude &&
      location.longitude === this.markerQueue.last?.longitude
    )
      return;

    // Once stabilisation has been achieved, only use a position with accuracy greater
    // than 25 if the previous position also had an accuracy less than 25. Should help
    // to prevent fluctuations.
    if (
      this.positioningStabalised &&
      this.trackingQueue.last!.accuracy < 25 &&
      location.accuracy > 25 &&
      this.isMobile
    ) {
      return;
    }

    this.markerQueue.insert(location);
    const position = createGeoJSONGeometryPoint([
      location.longitude,
      location.latitude,
    ]);

    this.averagedAccuracy = this.generateAccuracyCircle(position);

    if (location.bearing) {
      if (!this.headingDisplayed) {
        // enables heading cone if bearing is supplied and not previously enabled
        this.updateUserLocationStyle(true, true);
        this.headingDisplayed = true;
      }
    } else {
      if (this.headingDisplayed) {
        // disables heading cone if bearing is not available and not already removed
        this.updateUserLocationStyle(true, false);
        this.headingDisplayed = false;
      }
    }

    const properties = {
      heading: location.bearing || 0,
      floor_id: undefined, // set to undefined so the location dot appears on all floor levels
      accuracy: this.averagedAccuracy,
    };

    if (this.positionOnRoute) {
      const routePosition =
        this.routingControl.getNearestRoutePosition(position);
      if (routePosition) {
        if (routePosition!.properties.dist) {
          properties.accuracy = routePosition!.properties.dist;
        }
        position.coordinates = routePosition!.geometry.coordinates;
      }
    }

    animateBetweenTwoPoints(this.LMMap.getMapboxMap(), position, properties);
    this.lastPosition = position;
    // Do not show the positioning dot until at least 3 positions have been received
    // and their averaged accuracy is less than a threshold. If this has not been
    // achieved after 10 positions or 8 seconds, then show the dot. Only do this on
    // mobile devices.

    const stable = this.averagedAccuracy < 0.002 && this.markerQueue.length > 2;
    const tenPositions = this.trackingQueue.length > 9;

    if (
      !this.positioningStabalised &&
      (!this.isMobile || stable || tenPositions)
    ) {
      this.positioningStabalised = true;
      this.updateUserLocationStyle(true, false);
    }

    if (!this.positioningStabalised) {
      setTimeout(() => {
        if (!this.positioningStabalised) {
          this.positioningStabalised = true;
          this.updateUserLocationStyle(true, false);
        }
      }, 8000);
    }
  }

  public getCurrentPosition(): {
    latitude: number;
    longitude: number;
  } | null {
    if (this.lastPosition) {
      return {
        longitude: this.lastPosition.coordinates[0],
        latitude: this.lastPosition.coordinates[1],
      };
    }
    return this.lastPosition;
  }

  private layerNotDefined = (layerId: string) => {
    return !this.layerDelegate.getLayer(layerId);
  };

  private sourceNotDefined = (sourceId: string) => {
    return !this.LMMap.getMapboxMap().getSource(sourceId);
  };

  /**
   *
   * @param hex a hex colour for the user location dot
   * @param borderColour a border colour for the dot
   * @param displayPulse whether to display a pulsing animation
   */
  private updateUserLocationStyle = (
    displayPulse: boolean,
    displayCone: boolean,
  ) => {
    if (!this.positioningStabalised) return;
    this.LMMap.getMapboxMap().removeImage("location-dot");
    this.LMMap.getMapboxMap().addImage(
      "location-dot",
      this.createLocationDot(displayPulse, displayCone),
      { pixelRatio: 1.33 },
    );
  };

  private createUserLocationLayer(): void {
    const userLocationLayerDoesNotExist = this.layerNotDefined(
      USER_LOCATION_LAYER_ID,
    );
    if (userLocationLayerDoesNotExist) {
      this.layerDelegate.addSource(USER_LOCATION_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    }

    try {
      this.layerDelegate.addSource(USER_LOCATION_ACCURACY_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    } catch (error) {} // already exists ignore

    const doesLayerNotExist = this.layerNotDefined(USER_LOCATION_LAYER_ID);
    if (doesLayerNotExist) {
      this.layerDelegate.addLayer({
        id: USER_LOCATION_ACCURACY_ID,
        type: "fill",
        source: USER_LOCATION_ACCURACY_SOURCE_ID,
        paint: {
          "fill-color": "rgba(91, 148, 198, 0.3)",
        },
      });

      const layerLayout: SymbolLayout = {
        "icon-image": "location-dot",
        "icon-offset": [0, 0],
        "icon-allow-overlap": true,
        "icon-rotate": ["get", "heading"],
        "icon-rotation-alignment": "map",
      };

      this.layerDelegate.addLayer({
        id: USER_LOCATION_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_SOURCE_ID,
        layout: layerLayout,
      });
    }
  }

  private createLocationTrackingLayer(): void {
    const trackingSourceUndefined = this.sourceNotDefined(
      LOCATION_TRACKING_SOURCE_ID,
    );

    if (trackingSourceUndefined) {
      this.layerDelegate.addSource(LOCATION_TRACKING_SOURCE_ID, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    const trackingLayerUndefined = this.layerNotDefined(
      LOCATION_TRACKING_LAYER_ID,
    );

    if (trackingLayerUndefined) {
      this.layerDelegate.addLayer({
        id: LOCATION_TRACKING_LAYER_ID,
        source: LOCATION_TRACKING_SOURCE_ID,
        type: "circle",
        paint: {
          "circle-color": ["get", "colour"],
          "circle-stroke-width": ["get", "strokeWidth"],
          "circle-radius": 3,
        },
      });
    }

    const lineSourceUndefined = this.sourceNotDefined(
      LOCATION_TRACKING_LINE_SOURCE_ID,
    );

    if (lineSourceUndefined) {
      this.layerDelegate.addSource(LOCATION_TRACKING_LINE_SOURCE_ID, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    const lineLayerUndefined = this.layerNotDefined(
      LOCATION_TRACKING_LINE_LAYER_ID,
    );

    if (lineLayerUndefined) {
      this.layerDelegate.addLayer({
        id: LOCATION_TRACKING_LINE_LAYER_ID,
        source: LOCATION_TRACKING_LINE_SOURCE_ID,
        type: "line",
        paint: { "line-color": "grey" },
      });
    }

    console.warn(
      `Location tracking layer created. Raw positioning data will be displayed on the map. The colour of a position is determined by the accuracy of the position. %cBlue%c is most accurate, then %cgreen%c, %cyellow%c, %corange%c, %cred%c; and %cblack%c is least accurate. Positions with an altitude (i.e. outdoor positions) will have a %cthicker stroke width%c.`,
      ...[
        ...["color: #577590", ""],
        ...["color: #76c893", ""],
        ...["color: #f9c74f", ""],
        ...["color: #f67c2d", ""],
        ...["color: #da1e28", ""],
        ...["color: #000000", ""],
        ...["font-weight: bold;", ""],
      ],
    );
  }

  private updateTrackingLayer(): void {
    const positions = this.trackingQueue.fetch();

    const pointFeatures = positions.map((position) => {
      return createGeoJSONFeature(
        {
          colour: this.determinePositionColourFromAccuracy(position),
          strokeWidth: position.altitude !== null ? 2 : 0.5,
        },
        { type: "Point", coordinates: [position.longitude, position.latitude] },
      );
    });

    const pointFeatureCollection =
      createGeoJSONFeatureCollection(pointFeatures);

    const trackingSource = this.layerDelegate?.getSourceProxy(
      LOCATION_TRACKING_SOURCE_ID,
    );

    if (trackingSource) {
      trackingSource.setData(pointFeatureCollection);
    }

    const lineFeatures = positions.reduce((acc: Feature[], position, index) => {
      if (index === 0) return acc;

      const feature = createGeoJSONFeature(
        {},
        {
          type: "LineString",
          coordinates: [
            [positions[index - 1].longitude, positions[index - 1].latitude],
            [position.longitude, position.latitude],
          ],
        },
      );

      return [...acc, feature];
    }, []);

    const lineFeatureCollection = createGeoJSONFeatureCollection(lineFeatures);

    const lineSource = this.layerDelegate?.getSourceProxy(
      LOCATION_TRACKING_LINE_SOURCE_ID,
    );

    if (lineSource) {
      lineSource.setData(lineFeatureCollection);
    }
  }

  private removeLocationLayers() {
    this.layerDelegate.removeLayer(USER_LOCATION_LAYER_ID);
    this.layerDelegate.removeLayer(USER_LOCATION_ACCURACY_ID);
    this.layerDelegate.removeLayer(LOCATION_TRACKING_LAYER_ID);
    this.layerDelegate.removeLayer(LOCATION_TRACKING_LINE_LAYER_ID);
    this.layerDelegate.removeSource(USER_LOCATION_SOURCE_ID);
    this.layerDelegate.removeSource(USER_LOCATION_ACCURACY_SOURCE_ID);
    this.layerDelegate.removeSource(LOCATION_TRACKING_SOURCE_ID);
    this.layerDelegate.removeSource(LOCATION_TRACKING_LINE_SOURCE_ID);
  }

  private determinePositionColourFromAccuracy(position: Marker) {
    const { accuracy } = position;

    if (accuracy < 1) return "#4a6572";
    if (accuracy < 2) return "#577590";
    if (accuracy < 3) return "#5e8b9d";
    if (accuracy < 4) return "#6591aa";
    if (accuracy < 5) return "#4d908e";
    if (accuracy < 6) return "#43aa8b";
    if (accuracy < 7) return "#76c893";
    if (accuracy < 8) return "#90be6d";
    if (accuracy < 9) return "#a9d77e";
    if (accuracy < 10) return "#c6e18b";
    if (accuracy < 11) return "#e3e965";
    if (accuracy < 12) return "#f9c74f";
    if (accuracy < 13) return "#f9b131";
    if (accuracy < 14) return "#f8961e";
    if (accuracy < 15) return "#f67c2d";
    if (accuracy < 16) return "#f3722c ";
    if (accuracy < 17) return "#f94144";
    if (accuracy < 18) return "#e63946";
    if (accuracy < 19) return "#da1e28";
    if (accuracy < 20) return "#c9184a";
    if (accuracy < 22) return "#a52a2a";
    if (accuracy < 24) return "#6e260e";
    return "#000000";
  }

  private createLocationDot(displayPulse: boolean, displayCone: boolean) {
    const size = 80;

    /**
     * Modified version of code referenced from https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
     */
    const locationDot: {
      width: number;
      height: number;
      data: Uint8Array | Uint8ClampedArray;
      context: CanvasRenderingContext2D | null;
      lmMap: LivingMap;
      userLocationColour: UserLocationColour;
      borderColour: string;
      onAdd: () => void;
      render: () => boolean;
    } = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),
      context: null,
      lmMap: this.LMMap,
      userLocationColour: this.userLocationColour,
      borderColour: this.borderColour,

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const duration = 2500;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;

        const context = this.context;

        const toRadians = (deg: number) => (deg * Math.PI) / 180;

        context!.clearRect(0, 0, this.width, this.height);

        if (displayPulse) {
          // Draw the outer circle.
          context!.beginPath();
          context!.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2,
          );
          context!.fillStyle = `rgba(${this.userLocationColour.r}, ${
            this.userLocationColour.g
          }, ${this.userLocationColour.b}, ${1 - t})`;
          context!.fill();
        }

        // TODO: Add back in if we get bearing
        // Draw the directional cone
        if (displayCone) {
          context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 0.4)`;
          context!.beginPath();
          context!.moveTo(this.width / 2, this.height / 2);
          context!.arc(
            this.width / 2,
            this.height / 2,
            size / 2,
            toRadians(240),
            toRadians(300),
          );
          context!.lineTo(this.width / 2, this.height / 2);
          context!.closePath();
          context!.fill();
        }

        // Draw the inner circle.
        context!.beginPath();
        context!.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 1)`;
        context!.strokeStyle = this.borderColour;
        context!.lineWidth = 4;
        context!.shadowOffsetX = 0;
        context!.shadowOffsetY = 0;
        context!.shadowBlur = 8;
        context!.shadowColor = "rgba(0, 0, 0, 0.3)";
        context!.fill();
        context!.stroke();

        // Update this image's data with data from the canvas.
        this.data = context!.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        this.lmMap.getMapboxMap().triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    return locationDot;
  }
}
