import { useEffect, useRef, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import classNames from "classnames";
import LivingMap, {
  LMFeature,
  LivingMapOptions,
} from "@livingmap/core-mapping";
import {
  Button,
  Dialog,
  FloatingIconButton,
  FloorSelector,
  Icon,
  Spinner,
  Tooltip,
  ZoomControl,
} from "@livingmap/core-ui-v2";

import {
  MappedBookmark,
  MappedFloor,
  Region,
} from "../../redux/services/types";
import useLocationPermission from "../../hooks/useLocationPermission";
import useMotionAndOrientationPermission from "../../hooks/useMotionAndOrientationPermission";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { storeLocation } from "../../redux/slices/applicationSlice";
import useResponsive from "../../hooks/useResponsive";
import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";

import FloorControl from "./plugins/floor-control";
import ClusteredPinPlugin from "./plugins/clustered-pin-control";
import InteractionPlugin from "./plugins/interaction-control";
import { PLUGIN_IDS } from "./plugins/types/index";
import PositionPlugin from "./plugins/position-control";
import LayerIconPlugin from "./plugins/layer-icon-control";
import RoutingPlugin from "./plugins/routing-control";
import RegionPlugin from "./plugins/regions-control";

import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { push } from "../../utils/navigate";
import { Path, RoutingPath } from "../../utils/types";
import { AnalyticsEvent } from "../../utils/analyticsTypes";

import CompassButton from "../CompassButton/CompassButton";
import LocationButton, {
  LocationStatus,
} from "../LocationButton/LocationButton";

import { setLocationStatus } from "../../redux/slices/applicationSlice";

import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./Map.module.scss";
import DebugControl from "./plugins/debug-control";
import { useTranslation } from "react-i18next";

export interface MapCentre {
  lat: number;
  lng: number;
}

interface LocationOptions {
  heading?: number;
}

interface Props {
  dataQA: string;
  mapID: string;
  zoom: number;
  maxZoom: number;
  minZoom: number;
  center: [number, number];
  extent: [number, number, number, number];
  bearing: number;
  mapStyle: string;
  accessToken: string;
  floor: MappedFloor;
  floors: MappedFloor[];
  regions: Region[];
  bookmarks: MappedBookmark[];
  attributionHTML: string;
  onFeatureSelect?: (feature: LMFeature) => void;
  onMapReady?: (map: LivingMap) => void;
  onFloorChange?: (floor: MappedFloor) => void;
  enableUI?: boolean;
  enableUserLocation?: boolean;
  mapInstanceRef: React.MutableRefObject<LivingMap | null>;
  positionOnRoute: boolean;
}

const Map: React.FC<Props> = ({
  dataQA,
  mapID,
  bearing,
  center,
  extent,
  maxZoom,
  minZoom,
  zoom,
  mapStyle,
  accessToken,
  floor,
  floors,
  regions,
  bookmarks,
  attributionHTML,
  onFeatureSelect,
  onMapReady,
  enableUI = true,
  enableUserLocation = true,
  onFloorChange,
  mapInstanceRef,
  positionOnRoute,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Map state
  const [isLoading, setIsLoading] = useState(false);
  const [isMapAttributionOpen, setIsMapAttributionOpen] = useState(false);
  const [liveBearing, setLiveBearing] = useState(0);
  const [liveZoom, setLiveZoom] = useState(0);
  const [liveFloors, setLiveFloors] = useState<MappedFloor[]>([]);
  const [locationRequestSuccessful, setLocationRequestSuccessful] =
    useState(false);

  const isOnJourneyScreen = useMatch(Path.JOURNEY);

  // Get location status and coords from redux
  const { locationStatus, queryParamsConfig } = useAppSelector(
    (state) => state.application,
  );

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  // Location permission hook
  const { handleLocationRequest } = useLocationPermission({
    setLocationMarker: ({
      latitude,
      longitude,
      bearing,
      altitude,
      accuracy,
    }) => {
      positionControlInstance.current?.setMarker({
        latitude,
        longitude,
        floorID: floor.id,
        floor: floor.floor,
        bearing,
        altitude,
        accuracy,
        ts: new Date(),
      });
    },
  });

  const { requestMotionAndOrientationPermission } =
    useMotionAndOrientationPermission();

  const { Mobile } = useResponsive();

  // Container for the map
  const mapContainer = useRef<HTMLDivElement | null>(null);

  // Map Plugins
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const interactionControlInstance = useRef<InteractionPlugin | null>(null);
  const positionControlInstance = useRef<PositionPlugin | null>(null);
  const layerIconControlInstance = useRef<LayerIconPlugin | null>(null);
  const routingControlInstance = useRef<RoutingPlugin | null>(null);
  const regionControlInstance = useRef<RegionPlugin | null>(null);
  const debugControlInstance = useRef<DebugControl | null>(null);

  //i18next translation hook
  const { t } = useTranslation();

  // Ease the map to the user location when we have locationRequestSuccessful is currently false
  useEffect(() => {
    const position = positionControlInstance.current?.getCurrentPosition();
    if (!locationRequestSuccessful && position) {
      setLocationRequestSuccessful(true);
      mapInstanceRef.current?.getMapboxMap().easeTo({
        center: [position.longitude, position.latitude],
      });
    }
  }, [locationStatus, locationRequestSuccessful, mapInstanceRef]);

  useEffect(() => {
    if (locationStatus === LocationStatus.FOUND)
      logAnalyticsEvent({ event_type: AnalyticsEvent.SHOW_POSITION_ON });
  }, [locationStatus, logAnalyticsEvent]);

  // Map initialisation
  useEffect(() => {
    setIsLoading(true);

    const mapConfig: LivingMapOptions = {
      accessToken,
      zoom,
      maxZoom,
      minZoom,
      center,
      extent,
      bearing,
      style: mapStyle,
      hash: true,
      enablePitchWithRotate: true,
      enableTouchPitch: true,
    };

    const map = new LivingMap(mapContainer.current!, mapConfig);

    floorControlInstance.current = new FloorControl(PLUGIN_IDS.FLOOR, map);

    clusteredPinControlInstance.current = new ClusteredPinPlugin(
      PLUGIN_IDS.CLUSTERED_PIN,
      map,
      floorControlInstance.current,
    );

    interactionControlInstance.current = new InteractionPlugin(
      PLUGIN_IDS.INTERACTION,
      map,
    );

    routingControlInstance.current = new RoutingPlugin(
      PLUGIN_IDS.ROUTING,
      map,
      floors,
      onFloorChange,
    );

    positionControlInstance.current = new PositionPlugin(
      PLUGIN_IDS.USER_LOCATION,
      map,
      positionOnRoute,
      routingControlInstance.current,
      queryParamsConfig.debug === "enable",
    );

    layerIconControlInstance.current = new LayerIconPlugin(
      PLUGIN_IDS.LAYER_ICON,
      map,
    );

    regionControlInstance.current = new RegionPlugin(
      PLUGIN_IDS.REGION,
      map,
      regions,
      floors,
    );

    debugControlInstance.current = new DebugControl(PLUGIN_IDS.DEBUG, map);

    map.addPlugin(floorControlInstance.current);
    map.addPlugin(clusteredPinControlInstance.current);
    map.addPlugin(interactionControlInstance.current);
    map.addPlugin(positionControlInstance.current);
    map.addPlugin(layerIconControlInstance.current);
    map.addPlugin(routingControlInstance.current);
    map.addPlugin(regionControlInstance.current);
    map.addPlugin(debugControlInstance.current);

    map.create();

    map.on("style.load", () => {
      floorControlInstance.current?.activate();
      floorControlInstance.current?.setGroundFloor(floors);
      floorControlInstance.current?.setAllFloors(floors);
      floorControlInstance.current?.setActiveFloor(floor);

      interactionControlInstance.current?.activate();

      positionControlInstance.current?.activate();

      clusteredPinControlInstance.current?.activate();

      layerIconControlInstance.current?.activate();

      routingControlInstance.current?.activate();

      regionControlInstance.current?.activate();

      if (onFeatureSelect) {
        interactionControlInstance.current?.subscribeToFeatureSelect(
          onFeatureSelect,
        );
      }

      // Initialise map instance and state values
      mapInstanceRef.current = map;
      setLiveBearing(bearing);
      setLiveZoom(zoom);
      map.getMapboxMap().setMaxPitch(60);

      if (!regionControlInstance.current) {
        setLiveFloors(floors);
      } else {
        setLiveFloors(regionControlInstance.current.getAvailableFloors());
      }
    });

    map.on("render", function () {
      map?.getMapboxMap().resize();
    });

    map.on("load", () => {
      // Add the instance of LivingMap to the window object to assist with testing
      if (process.env.REACT_APP_STAGING === "true") {
        window.livingMap = map;
        console.log(
          "LivingMap instance injected into window.livingMap successfully",
        );
      }

      if (queryParamsConfig["ui-gestures"] === "disable") {
        map.getMapboxMap().scrollZoom.disable();
        map.getMapboxMap().dragPan.disable();
        map.getMapboxMap().doubleClickZoom.disable();
        map.getMapboxMap().touchZoomRotate.disable();
      }

      if (queryParamsConfig.consoleLocation === "enable") {
        dispatch(setLocationStatus(LocationStatus.FOUND));
        setLocationApi(map);
      }

      setIsLoading(false);
      onMapReady && onMapReady(map);
      handleRegionUpdate();

      logAnalyticsEvent({
        event_type: AnalyticsEvent.MAP_LOAD,
      });
    });

    map.getMapboxMap().on("rotate", () => {
      setLiveBearing(map.getMapboxMap().getBearing());
    });

    map.getMapboxMap().on("zoom", () => {
      setLiveZoom(Math.floor(map.getMapboxMap().getZoom()));
    });

    map.getMapboxMap().on("resize", () => {
      // timeout to fix flickering issue
      setTimeout(() => map.getMapboxMap().resize(), 50);
    });

    map.getMapboxMap().on("zoomend", handleRegionUpdate);
    map.getMapboxMap().on("dragend", handleRegionUpdate);
    map.getMapboxMap().on("moveend", handleRegionUpdate);

    // eslint-disable-next-line
  }, [mapID]); // this is the only required dependency

  const setLocationApi = (map: LivingMap): void => {
    const getCenter = (): mapboxgl.LngLat | undefined => {
      return map.getMapboxMap().getCenter();
    };

    const setUserLocation = (
      userLocation: mapboxgl.LngLat,
      options: LocationOptions = {},
    ) => {
      const bearing = options.heading ? options.heading : null;
      positionControlInstance.current?.setMarker({
        longitude: userLocation.lng,
        latitude: userLocation.lat,
        bearing,
        altitude: null,
        accuracy: 0,
        floor: "",
        floorID: 0,
        ts: new Date(),
      });
    };

    window.api = {
      setUserLocation: setUserLocation.bind({}),
      getCenter: getCenter.bind({}),
    };
  };

  const handleRegionUpdate = () => {
    if (regionControlInstance.current) {
      regionControlInstance.current.updateAvailableFloors();
      setLiveFloors(regionControlInstance.current.getAvailableFloors());
    }
  };

  const handleMapZoomIn = () => {
    mapInstanceRef?.current?.zoomOutOneLevel();
  };

  const handleMapZoomOut = () => {
    mapInstanceRef?.current?.zoomInOneLevel();
  };

  const handleFloorSelect = (newFloor: MappedFloor) => {
    floorControlInstance.current?.setActiveFloor(newFloor);
    onFloorChange && onFloorChange(newFloor);

    logAnalyticsEvent({
      event_type: AnalyticsEvent.MAP_LEVEL_CHANGE,
      event_data: {
        floor_from: floor.floor,
        floor_to: newFloor.floor,
      },
    });
  };

  const handleBookmarkSelect = (bookmark: MappedBookmark) => {
    mapInstanceRef.current?.getMapboxMap().easeTo({
      center: [bookmark.center.longitude, bookmark.center.latitude],
      bearing: bookmark.bearing,
      zoom: bookmark.zoom,
    });

    floorControlInstance.current?.setActiveFloor(bookmark.floor);

    logAnalyticsEvent({
      event_type: AnalyticsEvent.BOOKMARK_SELECT,
      event_data: bookmark.name,
    });
  };

  const handleLocationButtonClick = () => {
    const position = positionControlInstance.current?.getCurrentPosition();
    if (locationStatus === LocationStatus.FOUND && position) {
      mapInstanceRef.current?.getMapboxMap().easeTo({
        center: [position.longitude, position.latitude],
      });
    } else {
      handleLocationRequest();
    }

    requestMotionAndOrientationPermission();
  };

  return (
    <div data-qa={dataQA} className={styles.container}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Spinner dataQA="map-loading-spinner" type="BeatLoader" />
        </div>
      )}
      <div ref={mapContainer} className={styles.map} />
      {enableUI && floors.length > 1 && (
        <div className={styles.floorSelectorContainer}>
          <Tooltip
            dataQA="floor-selector-tooltip"
            className={styles.tooltip}
            triggerComponent={
              <FloorSelector
                currentUserFloor={null}
                dataQA="map-floor-selector"
                currentFloor={Object.keys(liveFloors).length ? floor : null}
                onFloorSelect={handleFloorSelect}
                onClick={() =>
                  logAnalyticsEvent({
                    event_type: AnalyticsEvent.LEVEL_SELECTOR_TOUCH,
                  })
                }
                options={Object.values(liveFloors)}
                bookmarks={bookmarks}
                onBookmarkSelect={handleBookmarkSelect}
                placement={Mobile ? "bottom" : "bottom-end"}
                findABuildingPlaceholder={t(
                  "home.floor_selector_find_building",
                )}
              />
            }
            children={<p>{t("home.floor_selector_tooltip")}</p>}
          />
        </div>
      )}
      {enableUI && (
        <div className={styles.mapControlsContainer}>
          <div className={styles.control}>
            <Tooltip
              dataQA="compass-tooltip"
              className={styles.tooltip}
              triggerComponent={
                <CompassButton
                  size={Mobile ? "medium" : "small"}
                  bearing={liveBearing}
                  dataQA="map-compass"
                  onClick={() => {
                    if (
                      mapInstanceRef?.current?.getMapboxMap().getBearing() === 0
                    ) {
                      mapInstanceRef?.current
                        ?.getMapboxMap()
                        .easeTo({ bearing });
                      logAnalyticsEvent({
                        event_type: AnalyticsEvent.NORTH_ORIENTATION_OFF,
                      });
                    } else {
                      mapInstanceRef?.current
                        ?.getMapboxMap()
                        .easeTo({ bearing: 0 });
                      logAnalyticsEvent({
                        event_type: AnalyticsEvent.NORTH_ORIENTATION_ON,
                      });
                    }
                  }}
                />
              }
              children={<p>{t("home.compass_tooltip")}</p>}
            />
          </div>
          <div
            className={classNames({
              [styles.bottom]: isOnJourneyScreen,
            })}
          >
            {isOnJourneyScreen && (
              <div
                className={classNames(
                  styles.control,
                  styles.journeyOverviewControl,
                )}
              >
                <FloatingIconButton
                  dataQA={"journey-overview-button"}
                  icon={"JourneyOverviewIcon"}
                  onClick={() => {
                    dispatch(storeLocation(location));

                    const { fromName, fromId, toName, toId } =
                      isOnJourneyScreen.params;

                    dispatch(
                      push({
                        pathOrLocation: buildRoutingPath(
                          RoutingPath.JOURNEY_OVERVIEW,
                          {
                            fromId,
                            fromName,
                            toId,
                            toName,
                          },
                        ),
                      }),
                    );
                  }}
                  rounded
                  size={Mobile ? "medium" : "small"}
                />
              </div>
            )}
            {enableUserLocation && (
              <div className={classNames(styles.control)}>
                <Tooltip
                  dataQA="user-location-tooltip"
                  className={styles.tooltip}
                  triggerComponent={
                    <LocationButton
                      dataQA="map-user-location"
                      onClick={handleLocationButtonClick}
                      status={locationStatus}
                      size={Mobile ? "medium" : "small"}
                    />
                  }
                  children={<p>{t("home.locate_me_tooltip")}</p>}
                />
              </div>
            )}
          </div>
          <div className={styles.zoomControl}>
            <Tooltip
              dataQA="zoom-tooltip"
              className={styles.tooltip}
              triggerComponent={
                <ZoomControl
                  dataQA="map-zoom-control"
                  maxZoomReached={liveZoom >= maxZoom}
                  minZoomReached={liveZoom <= minZoom}
                  onZoomInClick={() => {
                    setLiveZoom(
                      Math.floor(
                        mapInstanceRef?.current?.getMapboxMap().getZoom()!,
                      ),
                    );
                    handleMapZoomIn();
                  }}
                  onZoomOutClick={() => {
                    setLiveZoom(
                      Math.floor(
                        mapInstanceRef?.current?.getMapboxMap().getZoom()!,
                      ),
                    );
                    handleMapZoomOut();
                  }}
                />
              }
              children={<p>{t("home.zoom_tooltip")}</p>}
            />
          </div>
        </div>
      )}
      <Icon
        dataQA="map-attribution"
        type="InfoOutlinedIcon"
        onClick={() => {
          setIsMapAttributionOpen(true);
          logAnalyticsEvent({ event_type: AnalyticsEvent.ATTRIBUTION_OPEN });
        }}
        className={styles.attribution}
      />
      <Dialog
        isOpen={isMapAttributionOpen}
        dataQA="map-attribution-dialog"
        onClose={() => {}}
        maxWidth={300}
      >
        <div className={styles.attributionDialog}>
          <div
            className={styles.attributionDialogContent}
            dangerouslySetInnerHTML={{ __html: attributionHTML }}
          />
          <Button
            dataQA="map-attribution-confirm-buttom"
            onClick={() => setIsMapAttributionOpen(false)}
            color="black"
            rounded
            className={styles.mapAttributionConfirmButton}
          >
            {t("attribution_dialog.button_text")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Map;
